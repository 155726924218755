function copy(o1, o2) {
  const result = JSON.parse(JSON.stringify(o1));
  Object.keys(o2).forEach((k) => {
    if (typeof o2[k] === "object" && o2[k] !== null && !Array.isArray(o2[k])) {
      result[k] = copy(o1[k], o2[k]);
    } else if (Array.isArray(o2[k])) {
      result[k] = o2[k]
        ? JSON.parse(JSON.stringify(o2[k]))
        : JSON.parse(JSON.stringify(o1[k]));
    } else {
      result[k] = o2[k] === 0 ? o2[k] : o2[k] || o1[k];
    }
  });
  return result;
}
export default (obj1, obj2) => {
  return copy(obj1, obj2);
};
