<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="optionsTable"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" scrollable max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
                Создать
              </v-btn>
            </template>
            <v-card class="pa-1">
              <v-card-title class="mb-5 align-start">
                <span class="text-h5 mr-2">{{ formTitle }}</span>
                <div class="text-h5 accent--text">
                  {{ itemCurrentName }}
                </div>
              </v-card-title>

              <v-card-text>
                <v-row class="pt-4">
                  <v-col cols="12" class="py-0">
                    <v-tabs>
                      <v-tab v-for="lang in languagesList" :key="lang.iso">
                        <div class="d-flex align-center">
                          <country-flag
                            :country="lang.icon"
                            class="ma-0 elevation-3"
                          />
                          {{ lang.name }}
                        </div>
                      </v-tab>
                      <v-tab-item
                        v-for="(lang, index) in languagesList"
                        :key="lang.iso + '_tab_categories'"
                        eager
                        class="pt-3"
                      >
                        <v-text-field
                          :ref="`editedItem.name.${lang.iso}`"
                          v-model="editedItem.name[lang.iso]"
                          label="Название"
                          outlined
                          dense
                          :rules="[rules.required]"
                          :error="errors.name ? true : false"
                          :error-messages="errors.name"
                          @focus="errors.name = null"
                          @blur="
                            index === 0
                              ? slugPaste(editedItem.name[lang.iso])
                              : ''
                          "
                        ></v-text-field>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      ref="editedItem.slug"
                      v-model="editedItem.slug"
                      :rules="[rules.required]"
                      :error="errors.slug ? true : false"
                      :error-messages="errors.slug"
                      label="Slug"
                      @input="slugValue"
                      @focus="
                        errors.slug = null;
                        slugPaste(editedItem.name.ru);
                      "
                      @blur="slugPaste(editedItem.name.ru)"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-model="editedItem.sort"
                      label="Сортировка"
                      type="number"
                      outlined
                      dense
                      :error="errors.sort ? true : false"
                      :error-messages="errors.sort"
                      @focus="errors.sort = null"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <div class="d-inline-block">
                      <v-switch
                        color="primary"
                        class="mt-0"
                        v-model="editedItem.active"
                        label="Видимость"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="close"> Отмена </v-btn>
                <v-btn color="accent" @click="save">
                  <template v-if="editedIndex > -1"> Изменить </template>
                  <template v-else> Создать </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <template>
              <v-card class="pa-1">
                <v-card-title
                  class="text-h5 text-center justify-center flex-column mb-5"
                  >Удалить категорию?<br />
                  <span class="accent--text">{{
                    itemCurrentName
                  }}</span></v-card-title
                >

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn color="accent" outlined @click="closeDelete"
                    >Отмена</v-btn
                  >
                  <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <div class="d-inline-block">
          <v-switch
            v-model="item.active"
            color="primary"
            class="my-0"
            hide-details
            :ripple="false"
            @change="changeStatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import api from "../../../api";
import { mapGetters } from "vuex";
import rules from "@/mixins/rules";
import slug from "@/mixins/slug";
import copyValueObjs from "@/helpers/copyValueObjs";

export default {
  name: "CategoriesTable",
  mixins: [rules, slug],
  components: { CountryFlag },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Название",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Slug",
          value: "slug",
        },
        {
          text: "Сортировка",
          value: "sort",
          align: "center",
        },
        {
          text: "Видимость",
          value: "active",
          align: "center",
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      items: [],
      editedIndex: -1,
      editedItem: null,
      optionsTable: {},
      total: 0,
      loading: true,
      errors: {},
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новая категория" : "Изменить категорию";
    },
    itemCurrentName() {
      return this.editedIndex > -1 ? this.items[this.editedIndex].name : "";
    },
    ...mapGetters(["languagesList"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    optionsTable: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  created() {
    this.editedItem = { ...this.createItem() };
  },
  methods: {
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      const loadItem = await this.getItem(item.id);
      this.editedItem = copyValueObjs(this.createItem(), loadItem);
      this.dialog = true;
      this.resetErrors(this.editedItem, "editedItem");
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.removeItem(this.items[this.editedIndex].id);
      this.getItems();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
        this.errors = {};
        this.resetErrors(this.editedItem, "editedItem");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
      });
    },

    async save() {
      let formHasErrors = this.validationObject(this.editedItem, "editedItem");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      if (this.editedIndex > -1) {
        await this.updateItem();
        this.getItems();
      } else {
        await this.addItem();
        this.getItems();
      }
    },

    async getItems() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable;
      try {
        const req = await api.articles.categories({
          sortBy: sortBy && sortBy.length > 0 ? sortBy[0] : null,
          sortDesc: sortDesc && sortDesc.length > 0 ? sortDesc[0] : null,
          page: page,
          itemsPerPage: itemsPerPage,
        });
        if (req.data) {
          this.items = req.data.items;
          this.total = req.data.total;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async addItem() {
      const data = this.editedItem;
      try {
        await api.articles.createCategory(data);
        this.$store.dispatch("showSnackbar", {
          msg: `Категория "${this.editedItem.name.ru}" создана`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },

    async updateItem() {
      const data = this.editedItem;
      try {
        await api.articles.updateCategory(this.editedItem.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `Категория "${this.editedItem.name.ru}" изменена`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },

    async getItem(id) {
      try {
        const req = await api.articles.category(id);
        return req.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async removeItem(id) {
      try {
        const nameDelete = this.items[this.editedIndex].name;
        await api.articles.deleteCategory(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Категория "${nameDelete}" удалена`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },

    async changeStatus(item) {
      this.editedIndex = this.items.indexOf(item);
      const status = item.active;
      const loadItem = await this.getItem(item.id);
      this.editedItem = { ...loadItem, active: status };
      await this.updateItem();
    },

    createItem() {
      const keys = ["name"];
      let localeKeys = {};
      keys.forEach((key) => {
        let obj = {};
        this.languagesList.forEach((lang) => {
          obj[lang.iso] = "";
        });
        localeKeys[key] = obj;
      });
      return {
        ...localeKeys,
        slug: "",
        sort: 0,
        active: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
