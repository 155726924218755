<template>
  <div>
    <!-- Buttons -->
    <v-row class="py-5 px-5 justify-end" dense align="center">
      <v-col
        v-if="!banners.length || !banners.some((e) => e.rs === 'footer_banner')"
        cols="auto"
      >
        <v-btn color="secondary" @click="addBanner('footer_banner')">
          Добавить Баннер для футера
        </v-btn>
      </v-col>
      <v-col
        v-if="
          !banners.length || !banners.some((e) => e.rs === 'sidebar_banner')
        "
        cols="auto"
      >
        <v-btn color="secondary" @click="addBanner('sidebar_banner')">
          Добавить Баннер для Сайдбара
        </v-btn>
      </v-col>
      <v-col v-if="banners.length" cols="auto">
        <v-btn @click="saveBanners" color="accent" dark> Сохранить </v-btn>
      </v-col>
    </v-row>

    <v-form ref="form">
      <!-- Tabs -->
      <v-row class="py-5 px-5" dense align="center">
        <v-col cols="12" class="py-0">
          <v-tabs v-model="tab">
            <!-- Tab Buttons -->
            <v-tab v-for="lang in languagesList" :key="lang.iso">
              <div class="d-flex align-center">
                <country-flag :country="lang.icon" class="ma-0 elevation-3" />
                {{ lang.name }}
              </div>
            </v-tab>

            <!-- Tab Panels -->
            <v-tab-item
              v-for="lang in languagesList"
              :key="lang.iso + '_tab_tags'"
              eager
              class="pt-3"
            >
              <v-row v-if="banners.length">
                <v-col
                  v-for="(banner, bIndex) in banners"
                  :key="banner.id"
                  cols="12"
                >
                  <b class="mb-5 me-3">
                    {{
                      banner.rs === "footer_banner"
                        ? "Баннер футера"
                        : "Баннер Сайдбара"
                    }}
                  </b>
                  <v-btn color="error" outlined icon>
                    <v-icon small @click="deleteBanner(banner.id, banner.rs)">
                      mdi-delete
                    </v-icon>
                  </v-btn>

                  <!-- Name -->
                  <v-text-field
                    v-model="banner.name"
                    :rules="[rules.required]"
                    label="Name (общий для всех полей)"
                    class="mt-3"
                    outlined
                    dense
                  />

                  <!-- Link -->
                  <v-text-field
                    v-model="banner.links[lang.iso]"
                    :rules="[rules.required]"
                    :label="'Src (' + lang.name + ')'"
                    class="mt-3"
                    outlined
                    dense
                  />

                  <!-- File -->
                  <v-file-input
                    v-if="
                      !banner.src[lang.iso] ||
                      typeof banner.src[lang.iso] === 'object'
                    "
                    v-model="banner.src[lang.iso]"
                    :rules="[rules.required, rules.imgSize]"
                    label="Изображение"
                    accept="image/png, image/jpeg, svg"
                    outlined
                    dense
                    prepend-icon="mdi-image-plus"
                    @change="icon"
                  />

                  <!-- URL Input -->
                  <v-text-field
                    v-else
                    :value="banner.src[lang.iso]"
                    label="Изображение URL"
                    outlined
                    dense
                    readonly
                    prepend-icon="mdi-link"
                  />

                  <!-- Preview -->
                  <v-row>
                    <v-col cols="7">
                      <v-img
                        class="preview-image"
                        :src="
                          typeof banner.src[lang.iso] === 'object'
                            ? getBlobURL(banner.src[lang.iso])
                            : banner.src[lang.iso]
                        "
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-btn
                        fab
                        small
                        color="accent"
                        @click="clearIcon(bIndex, lang.iso)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../../api";
import CountryFlag from "vue-country-flag";

export default {
  name: "Banners",
  components: { CountryFlag },
  data() {
    return {
      editedItem: null,
      loading: true,
      errors: {},
      banners: [],
      tab: 0,
      rules: {
        required: (v) => !!v || "Это поле обязательное",
        imgSize: (v) =>
          !v || v.size < 2000000 || "Максимальный размер файла 2mb",
      },
    };
  },
  computed: {
    isFormValid() {
      return this.$refs.form.validate();
    },
    ...mapGetters(["languagesList"]),
  },
  async created() {
    await this.getBanners();
  },
  methods: {
    async getBanners() {
      try {
        const { data } = await api.banners.getItems();
        this.banners = data;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка загрузки баннеров",
          color: "error",
          show: true,
        });
        this.banners = [];
      }
    },

    saveBanners() {
      for (let banner of this.banners) {
        if (banner.id) {
          this.updateBanner(banner);
        } else {
          this.createBanner(banner);
        }
      }
    },

    // CREATE Banners
    async createBanner(banner) {
      if (!this.isFormValid) {
        await this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }

      try {
        const form = new FormData();
        form.append("rs", banner.rs);
        form.append("name", banner.name);
        Object.keys(banner.links).forEach((language) => {
          form.append(`links[${language}]`, banner.links[language]);
        });
        Object.keys(banner.src).forEach((language) => {
          form.append(`src[${language}]`, banner.src[language]);
        });

        await api.banners.createItem(form);
        this.$store.dispatch("showSnackbar", {
          msg: "Успешно сохранено",
          color: "success",
          show: true,
        });

        await this.getBanners();
      } catch (e) {
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка при создании",
          color: "error",
          show: true,
        });
        console.log(e);
      }
    },

    // UPDATE Banners
    async updateBanner(banner) {
      if (!this.isFormValid) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }

      try {
        const form = new FormData();
        form.append("rs", banner.rs);
        form.append("name", banner.name);
        Object.keys(banner.links).forEach((language) => {
          form.append(`links[${language}]`, banner.links[language]);
        });

        Object.keys(banner.src).forEach((language) => {
          if (typeof banner.src[language] === "object") {
            form.append(`src[${language}]`, banner.src[language]);
          }
        });

        await api.banners.updateItem(banner.id, form);
        this.$store.dispatch("showSnackbar", {
          msg: "Успешно сохранено",
          color: "success",
          show: true,
        });

        await this.getBanners();
      } catch (e) {
        console.log(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка сохранения",
          color: "error",
          show: true,
        });
      }
    },

    async deleteBanner(id, rs) {
      // delete saved banner
      if (![undefined, null].includes(id)) {
        try {
          await api.banners.deleteItem(id);
          this.$store.dispatch("showSnackbar", {
            msg: "Баннер удален",
            color: "success",
            show: true,
          });
        } catch (e) {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка удаления баннера",
            color: "error",
            show: true,
          });
          console.log(e);
        }
        await this.getBanners();

        // delete unsaved banner
      } else if (rs) {
        const index = this.banners.findIndex((e) => e.rs === rs);
        this.banners.splice(index, 1);
      }
    },

    icon(files) {
      if (files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(files);
        this.editedItem.image = files;
      } else {
        this.image = null;
        this.editedItem.image = null;
      }
    },

    clearIcon(index, lang) {
      this.banners[index].src[lang] = "";
    },

    addBanner(type) {
      this.banners.push({
        rs: type,
        name: "",
        links: {},
        src: {},
      });
    },

    getBlobURL(file) {
      return URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  border: 1px solid #999;
  aspect-ratio: 4.463;
  border-radius: 4px;
}
</style>
