<template>
  <v-dialog :value="true" max-width="848">
    <v-card>
      <v-card-title class="text-h5">
        Генерация HTML баннера статьи
      </v-card-title>
      <div class="mx-4">
        <v-text-field
          v-if="!uploadedImageUrl"
          v-model="url"
          label="URL ссылки"
          placeholder="Введите URL ссылки"
          outlined
          dense
        ></v-text-field>
        <v-file-input
          v-if="!uploadedImageUrl"
          :placeholder="'Укажите изображение. Соотношение сторон 4:1, макс ширина 800 px.'"
          outlined
          dense
          prepend-icon="mdi-camera"
          @change="selectImage"
        ></v-file-input>
        <img
          v-if="imagePreviewUrl"
          :src="imagePreviewUrl"
          class="image-preview"
          alt="preview image"
        />
        <div v-if="uploadedImageUrl && url" class="banner-html">
          <button class="banner-html__copy-btn" @click="copyToClipboard">
            <v-icon>mdi-content-copy</v-icon>
          </button>
          <pre class="banner-html__preview">{{ bannerHTML }}</pre>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" outlined @click="closeModal">
          Закрыть окно
        </v-btn>
        <v-btn
          v-if="!uploadedImageUrl"
          :disabled="!url || !image"
          @click="generateBannerHTML"
          color="accent"
        >
          Сгенрировать HTML баннера
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../../axios";

export default {
  model: {
    prop: "visible",
    event: "hide",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    articleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      url: null,
      image: null,
      imagePreviewUrl: null,
      uploadedImageUrl: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("hide", false);
      this.imagePreviewUrl = null;
      this.image = null;
    },
    selectImage(file) {
      if (!file) {
        this.image = null;
        this.imagePreviewUrl = null;
        this.uploadedImageUrl = null;
        return;
      }
      this.image = file;
      this.imagePreviewUrl = URL.createObjectURL(file);
    },
    async generateBannerHTML() {
      const fd = new FormData();
      fd.append("upload", this.image);
      const uploadURL = `${process.env.VUE_APP_URL_API}/admin/articles/${this.articleId}/upload-files`;
      try {
        const response = await axios.post(uploadURL, fd);
        console.log(response);
        this.uploadedImageUrl = response?.data?.url;
      } catch (error) {
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка при загрузке изображения",
          color: "error",
          show: true,
        });
        console.error(error);
      }
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.bannerHTML);
        this.$store.dispatch("showSnackbar", {
          msg: "HTML код баннера скопирован в буффер обмена",
          color: "success",
          show: true,
        });
      } catch (error) {
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка при копировании HTML кода баннера в буфер обмена",
          color: "error",
          show: true,
        });
        console.error(error);
      }
    },
  },
  computed: {
    bannerHTML() {
      return `<p>
  <a
    class="article-banner"
    href="${this.url}"
    style="display: block;
    aspect-ratio: 4/1;
    background-size: cover;
    background-position: center;
    background-image: url('${this.uploadedImageUrl}')"
    target="_blank">ARTICLE_BANNER</a>
</p>`;
    },
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  display: block;
  width: 100%;
  aspect-ratio: 4/1;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
  margin: auto;
  transition: 0.3s;
  margin-bottom: 24px;

  &:hover {
    scale: 1.02;
  }
}

.banner-html {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  margin: auto;
  padding-inline: 0px;
  font-size: 14px;

  &__preview {
    display: block;
    padding: 10px;
    overflow: auto;
  }

  &__copy-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    transition: scale 0.15s;

    &:hover {
      scale: 1.05;
    }
  }
}
</style>
