<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="optionsTable"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            :retain-focus="false"
            fullscreen
            hide-overlay
            max-width="100%"
            persistent
            no-click-animation
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="accent" dark class="mb-2" v-bind="attrs" v-on="on">
                Создать
              </v-btn>
            </template>
            <v-card class="pa-1">
              <v-card-title class="align-start">
                <v-container class="py-0">
                  <span class="text-h5 mr-2">{{ formTitle }}</span>
                  <div class="text-h5 accent--text">
                    {{ itemCurrentName }}
                  </div>
                </v-container>
              </v-card-title>
              <v-card-text>
                <v-container class="py-0">
                  <v-row class="pt-4">
                    <v-col cols="12" class="py-0">
                      <v-tabs>
                        <v-tab v-for="lang in languagesList" :key="lang.iso">
                          <div class="d-flex align-center">
                            <country-flag
                              :country="lang.icon"
                              class="ma-0 elevation-3"
                            />
                            {{ lang.name }}
                          </div>
                        </v-tab>
                        <v-tab-item
                          v-for="(lang, index) in languagesList"
                          :key="lang.iso + '_tab_article'"
                          eager
                          class="pt-3"
                        >
                          <v-text-field
                            :ref="`editedItem.name.${lang.iso}`"
                            v-model="editedItem.name[lang.iso]"
                            label="Название"
                            outlined
                            dense
                            :rules="[rules.required]"
                            :error="errors.name ? true : false"
                            :error-messages="errors.name"
                            @focus="errors.name = null"
                            @blur="index === 0 ? slugPaste() : ''"
                          ></v-text-field>
                          <v-text-field
                            :ref="`editedItem.seo_title.${lang.iso}`"
                            v-model="editedItem.seo_title[lang.iso]"
                            label="СЕО Заголовок"
                            outlined
                            dense
                            :rules="[rules.required]"
                            :error="errors.seo_title ? true : false"
                            :error-messages="errors.seo_title"
                            @focus="errors.seo_title = null"
                          ></v-text-field>
                          <v-textarea
                            :ref="`editedItem.seo_description.${lang.iso}`"
                            v-model="editedItem.seo_description[lang.iso]"
                            label="СЕО Описание"
                            outlined
                            dense
                            :rules="[rules.required]"
                            :error="errors.seo_description ? true : false"
                            :error-messages="errors.seo_description"
                            @focus="errors.seo_description = null"
                          ></v-textarea>
                          <v-text-field
                            :ref="`editedItem.title.${lang.iso}`"
                            v-model="editedItem.title[lang.iso]"
                            label="H1 - Заголовок"
                            outlined
                            dense
                            :rules="[rules.required]"
                            :error="errors.title ? true : false"
                            :error-messages="errors.title"
                            @focus="errors.title = null"
                          ></v-text-field>
                          <v-text-field
                            :ref="`editedItem.description.${lang.iso}`"
                            v-model="editedItem.description[lang.iso]"
                            label="Краткое описание"
                            outlined
                            dense
                            :rules="[rules.required]"
                            :error="errors.description ? true : false"
                            :error-messages="errors.description"
                            @focus="errors.description = null"
                          ></v-text-field>
                        </v-tab-item>
                      </v-tabs>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        ref="editedItem.slug"
                        v-model="editedItem.slug"
                        :rules="[rules.required]"
                        :error="errors.slug ? true : false"
                        :error-messages="errors.slug"
                        label="Slug"
                        @input="slugValue"
                        @focus="
                          errors.slug = null;
                          slugPaste(editedItem.name.ru);
                        "
                        @blur="slugPaste(editedItem.name.ru)"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="categoriesList.length" cols="12" class="py-0">
                      <v-select
                        ref="editedItem.category_id"
                        v-model="editedItem.category_id"
                        :items="categoriesList"
                        :rules="[rules.required]"
                        label="Категория"
                        item-text="name"
                        item-value="id"
                        item-color="secondary"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col v-if="tagsList.length" cols="12" class="py-0">
                      <v-combobox
                        ref="editedItem.tag_ids"
                        v-model="editedItem.tag_ids"
                        :rules="[rules.requiredCombobox]"
                        :items="tagsList"
                        :item-value="(obj) => obj.id"
                        :return-object="false"
                        item-color="secondary"
                        label="Теги"
                        multiple
                        clearable
                        dense
                        outlined
                        deletable-chips
                        small-chips
                      >
                        <template v-slot:selection="{ attrs, item, parent }">
                          <v-chip v-bind="attrs" color="secondary" label small>
                            <span class="pr-2">
                              {{ tagsList.find((tag) => tag.id === item).name }}
                            </span>
                            <v-icon small @click="parent.selectItem(item)">
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ index, on, attrs, item }">
                          <v-list-item v-on="on">
                            <v-checkbox
                              :input-value="
                                editedItem.tag_ids.find((id) => id === item.id)
                              "
                              color="secondary"
                            ></v-checkbox>
                            {{ item.name }}
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-file-input
                        v-model="fileName"
                        label="Изображение"
                        :rules="rulesIcon"
                        :error="errors.image ? true : false"
                        :error-messages="errors.image"
                        accept="image/png, image/jpeg, svg"
                        outlined
                        dense
                        prepend-icon="mdi-image-plus"
                        @change="icon"
                      ></v-file-input>
                    </v-col>
                    <v-col v-if="imageSrc" cols="12" class="pt-0 pb-5">
                      <v-row>
                        <v-col cols="7"><v-img :src="imageSrc" /></v-col>
                        <v-col cols="5">
                          <v-btn fab small color="accent" @click="clearIcon">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-if="editedItem.id !== undefined"
                      cols="12"
                      class="pt-0 pb-5"
                    >
                      <v-tabs>
                        <v-tab v-for="lang in languagesList" :key="lang.iso">
                          <div class="d-flex align-center">
                            <country-flag
                              :country="lang.icon"
                              class="ma-0 elevation-3"
                            />
                            {{ lang.name }}
                          </div>
                        </v-tab>
                        <v-tab-item
                          v-for="lang in languagesList"
                          :key="lang.iso + '_tab_content'"
                          eager
                          class="pt-3"
                        >
                          <ArticlesEditor
                            :value="editedItem.text[lang.iso]"
                            @input="(v) => (editedItem.text[lang.iso] = v)"
                            :article-id="editedItem.id"
                          />
                          <v-btn
                            color="accent"
                            dark
                            class="mb-2 mt-5"
                            @click="isShowArticleBannerModal = true"
                          >
                            Сгенерировать HTML баннера статьи
                          </v-btn>
                          <ArticleBannerGenerationModal
                            v-if="isShowArticleBannerModal"
                            v-model="isShowArticleBannerModal"
                            :article-id="editedItem.id"
                          />
                        </v-tab-item>
                      </v-tabs>
                    </v-col>
                    <v-alert v-else type="info" width="100%">
                      Редактирование текста статьи и возможность добавления
                      баннеров в статью будет доступно после создания публикации
                    </v-alert>
                    <v-col cols="12" class="py-0">
                      <div class="d-inline-block">
                        <v-switch
                          color="primary"
                          class="mt-0"
                          v-model="editedItem.active"
                          label="Видимость"
                        ></v-switch>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-4">
                <v-container class="d-flex py-0">
                  <v-spacer></v-spacer>
                  <v-btn class="me-3" color="accent" outlined @click="close">
                    Отмена
                  </v-btn>
                  <v-btn color="accent" @click="save">
                    <template v-if="editedIndex > -1"> Изменить </template>
                    <template v-else> Создать </template>
                  </v-btn>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <template>
              <v-card class="pa-1">
                <v-card-title
                  class="text-h5 text-center justify-center flex-column mb-5"
                  >Удалить категорию?<br />
                  <span class="accent--text">{{
                    itemCurrentName
                  }}</span></v-card-title
                >

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn color="accent" outlined @click="closeDelete"
                    >Отмена</v-btn
                  >
                  <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:item.active="{ item }">
        <div class="d-inline-block">
          <v-switch
            v-model="item.active"
            color="primary"
            class="my-0"
            hide-details
            :ripple="false"
            @change="changeStatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import api from "../../../api";
import { mapGetters } from "vuex";
import moment from "moment";
import rules from "@/mixins/rules";
import slug from "@/mixins/slug";
import ArticlesEditor from "@/components/ArticlesEditor.vue";
import copyValueObjs from "@/helpers/copyValueObjs";
import ArticleBannerGenerationModal from "@/components/Articles/ArticleBannerGenerationModal.vue";

export default {
  name: "ArticlesTable",
  mixins: [rules, slug],
  components: { CountryFlag, ArticlesEditor, ArticleBannerGenerationModal },
  data() {
    return {
      isShowArticleBannerModal: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Название",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Slug",
          value: "slug",
        },
        {
          text: "Создана",
          value: "created_at",
        },
        {
          text: "Видимость",
          value: "active",
          align: "center",
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      items: [],
      editedIndex: -1,
      editedItem: null,
      optionsTable: {},
      total: 0,
      loading: true,
      errors: {},
      rulesIcon: [
        (value) =>
          !value || value.size < 2000000 || "Максимальный размер файла 2mb",
      ],
      image: null,
      fileName: null,
      tagsList: [],
      categoriesList: [],
      editorConfig: {
        allowedContent: true,
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новая статья" : "Изменить статью";
    },
    itemCurrentName() {
      return this.editedIndex > -1 ? this.items[this.editedIndex].name : "";
    },
    ...mapGetters(["languagesList"]),
    imageSrc() {
      return this.image ? this.image : this.editedItem.image;
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.getTagList();
        this.getCategoriesList();
      } else {
        this.close();
      }
    },
    optionsTable: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  created() {
    this.editedItem = { ...this.createItem() };
  },
  methods: {
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      const loadItem = await this.getItem(item.id);
      this.editedItem = copyValueObjs(this.createItem(), loadItem);
      this.dialog = true;
      this.resetErrors(this.editedItem, "editedItem");
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.removeItem(this.items[this.editedIndex].id);
      this.getItems();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
        this.errors = {};
        this.clearIcon();
        this.resetErrors(this.editedItem, "editedItem");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
      });
    },

    async save() {
      let formHasErrors = this.validationObject(this.editedItem, "editedItem");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      if (this.editedIndex > -1) {
        await this.updateItem();
        this.getItems();
      } else {
        await this.addItem();
        this.getItems();
      }
    },

    async getItems() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable;
      try {
        const req = await api.articles.articles({
          sortBy: sortBy && sortBy.length > 0 ? sortBy[0] : null,
          sortDesc: sortDesc && sortDesc.length > 0 ? sortDesc[0] : null,
          page: page,
          itemsPerPage: itemsPerPage,
        });
        if (req.data) {
          this.items = req.data.items;
          this.total = req.data.total;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async addItem() {
      const data = this.makeFormData(this.editedItem);
      try {
        await api.articles.createArticle(data);
        this.$store.dispatch("showSnackbar", {
          msg: `Статья "${this.editedItem.name.ru}" создана`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },

    async updateItem() {
      const data = this.makeFormData(this.editedItem);
      data.append("_method", "PUT");
      for (const key in data.keys()) {
        console.log(`${key}: `, data.get(key));
      }
      try {
        await api.articles.updateArticle(this.editedItem.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `Статья "${this.editedItem.name.ru}" изменена`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },

    async getItem(id) {
      try {
        const req = await api.articles.article(id);
        return req.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async removeItem(id) {
      try {
        const nameDelete = this.items[this.editedIndex].name;
        await api.articles.deleteArticle(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Статья "${nameDelete}" удалена`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },

    async changeStatus(item) {
      this.editedIndex = this.items.indexOf(item);
      const status = item.active;
      const loadItem = await this.getItem(item.id);
      this.editedItem = { ...loadItem, active: status };
      await this.updateItem();
    },

    createItem() {
      const keys = [
        "name",
        "seo_title",
        "seo_description",
        "title",
        "description",
        "text",
        "content_description",
      ];
      let localeKeys = {};
      keys.forEach((key) => {
        let obj = {};
        this.languagesList.forEach((lang) => {
          obj[lang.iso] = "";
        });
        localeKeys[key] = obj;
      });
      return {
        ...localeKeys,
        slug: "",
        active: true,
        category_id: null,
        tag_ids: [],
      };
    },
    icon(files) {
      if (files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(files);
        this.editedItem.image = files;
      } else {
        this.image = null;
        this.editedItem.image = null;
      }
    },
    makeFormData() {
      const data = new FormData();
      for (const key in this.editedItem) {
        if (
          typeof this.editedItem[key] === "object" &&
          !(this.editedItem[key] instanceof File) &&
          !(this.editedItem[key] instanceof Date)
        ) {
          for (const lang in this.editedItem[key]) {
            data.append(`${key}[${lang}]`, this.editedItem[key][lang]);
          }
        } else if (typeof this.editedItem[key] === "boolean") {
          data.append(key, this.editedItem[key] ? 1 : 0);
        } else if (key === "image") {
          if (this.editedItem[key] instanceof File) {
            data.append(key, this.editedItem[key]);
          }
        } else {
          data.append(key, this.editedItem[key]);
        }
      }
      if (!this.editedItem.image) {
        data.append("delete_image", 1);
      }
      console.log("item: ", this.editedItem);
      return data;
    },
    clearIcon() {
      this.image = null;
      this.editedItem.image = null;
      this.fileName = null;
    },
    async getTagList() {
      try {
        const req = await api.articles.tagsList();
        if (req.data) {
          this.tagsList = req.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getCategoriesList() {
      try {
        const req = await api.articles.categoriesList();
        if (req.data) {
          this.categoriesList = req.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>

<style lang="scss" scoped></style>
