<template>
  <div>
    <v-card>
      <v-tabs>
        <v-tab>Статьи</v-tab>
        <v-tab>Категории</v-tab>
        <v-tab>Теги</v-tab>
        <v-tab>Баннеры</v-tab>
        <v-tab-item eager>
          <articles-table />
        </v-tab-item>
        <v-tab-item eager>
          <categories-table />
        </v-tab-item>
        <v-tab-item eager>
          <tags-table />
        </v-tab-item>
        <v-tab-item eager>
          <banners />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import CategoriesTable from "@/components/Articles/CategoriesTable";
import TagsTable from "@/components/Articles/TagsTable";
import ArticlesTable from "@/components/Articles/ArticlesTable";
import Banners from "@/components/Articles/Banners";
export default {
  name: "Articles",
  components: { CategoriesTable, TagsTable, ArticlesTable, Banners },
};
</script>

<style lang="scss" scoped></style>
