<template>
  <div>
    <editor :value="value" :config="editorConfig" @input="handleChange" />
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    articleId: {
      type: Number,
      required: false,
    },
  },
  components: { editor: CKEditor.component },
  computed: {
    editorConfig() {
      const token = this.$store.getters["admin/token"];
      let baseEditorConfig = { allowedContent: true, autoParagraph: false };
      if (this.articleId !== undefined) {
        baseEditorConfig = {
          ...baseEditorConfig,
          versionCheck: false,
          filebrowserImageUploadUrl: `${process.env.VUE_APP_URL_API}/admin/articles/${this.articleId}/upload-files`,
          filebrowserUploadUrl: `${process.env.VUE_APP_URL_API}/admin/articles/${this.articleId}/upload-files`,
          fileTools_requestHeaders: {
            Authorization: `Bearer ${token}`,
          },
        };
      }
      return baseEditorConfig;
    },
  },
  methods: {
    handleChange(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style scoped></style>
